'use client'

import {captureException} from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import {useEffect} from 'react'

const NotFoundPage = dynamic(() => import('@/containers/global-error-handler'), {
  ssr: false,
})

export default function NotFound({error}: {error: Error & {digest?: string}}) {
  useEffect(() => {
    captureException(error)
  }, [error])

  // eslint-disable-next-line no-console
  console.debug('The error page triggered.')

  return <NotFoundPage statusCode={'Unexpected Error'} />
}
